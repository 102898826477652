<!-- Header Start -->
<header
  class="w-full flex items-center justify-between p-2 text-white shadow-lg sticky top-0 left-0 right-0 z-10"
>
  <!-- Logo Start -->
  <div class="flex items-center">
    <!-- <button (click)="toggleNavBar()" mat-button class="p-1 mr-4">
      <mat-icon>menu</mat-icon>
    </button> -->
    <button mat-button class="p-1 mr-4">
      <div routerLink="dashboard" id="logo" class="h-11 p-0 w-24"><br /></div>
    </button>
    <button routerLink="dashboard" class="p-1 text-xl font-medium">YMS</button>
  </div>
  <!-- Logo End -->
  <!-- Nav Start -->
  <nav class="flex items-center justify-center">
    <!-- <a routerLink="about" class="text-white">
      <span class="p-4">About</span>
    </a> -->
    <div *ngIf="appStore?.user">
      <button mat-button [matMenuTriggerFor]="menu" class="p-0 m-0">
        <div
          ngbDropdownToggle
          id="userMenu"
          class="m-0 p-0"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <button
            class="bg-[#E6EBEF] text-black hover:bg-[#33C2FF] hover:text-white flex items-center justify-between px-1 md:px-6 py-1 rounded-full"
          >
            <span class="pr-4 pl-4 hidden md:block">
              {{ appStore.user?.displayName }}
            </span>
            <mat-icon class="pr-0 md:pr-8">account_circle</mat-icon>
          </button>
        </div>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <b>{{ appStore.user?.displayName }}</b>
        </button>
        <button mat-menu-item>
          {{ appStore.user?.email }}
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="signOut()">Sign Out</button>
      </mat-menu>
    </div>
  </nav>
  <!-- Nav End -->
</header>
<!-- Header End -->
<!-- <mat-toolbar
  color="primary"
  class="d-flex mb-3 bg-black justify-content-between shadow"
>
  <div class="d-flex align-items-center">
    <mat-icon
      aria-hidden="false"
      aria-label="menu icon"
      class="cursorPtr"
      (click)="triggerMenu()"
      >menu</mat-icon
    >
    <a href="/">
      <div routerLink="dashboard" class="d-flex align-items-center m-3">
        <img width="80px" class="yms-logo me-3" src="./assets/shell/logo.svg" />
        <h2 class="yms-app-title ps-3 text-light">YMS</h2>
      </div>
    </a>
  </div>
  <div class="d-flex align-items-center">
    <a href="/">
      <button mat-button routerLink="dashboard" class="text-light m-0 p-0">
        <mat-icon aria-hidden="false" aria-label="home icon">home</mat-icon>
      </button>
    </a>

    <button mat-button *ngIf="!authenticated" (click)="signIn()">
      Sign In
    </button>
    <div *ngIf="authenticated">
      <button mat-button [matMenuTriggerFor]="menu" class="p-0 m-0">
        <a
          ngbDropdownToggle
          id="userMenu"
          class="nav-link avatar-link m-0 p-0"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            src="{{ appStore.user?.avatar }}"
            class="rounded-circle align-self-center"
            style="width: 32px"
          />
          <span
            style="color: rgb(212, 224, 231); padding: 3px 5px; font-size: 10px"
            >&#9660;</span
          >
        </a>
      </button>
      <mat-menu class="bg-black" #menu="matMenu">
        <button mat-menu-item class="color-white">
          <b>{{ appStore.user?.displayName }}</b>
        </button>
        <button mat-menu-item class="color-white">
          {{ appStore.user?.email }}
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item class="color-white" (click)="signOut()">
          Sign Out
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar> -->
