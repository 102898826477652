import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { MsalService } from "@azure/msal-angular";

@Injectable()
export class AdminGuard {
  constructor(public msalService: MsalService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const account: any = this.msalService.instance.getActiveAccount();
    if (
      account !== null &&
      account?.accessToken !== null &&
      account?.idTokenClaims?.roles?.includes("NBL_YMS_ADMIN")
    ) {
      return true;
    } else {
      return true;
    }
  }
}
