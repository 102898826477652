import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { RedirectRequest } from "@azure/msal-browser/dist/request/RedirectRequest";

import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";

import { User } from "../model/user";
import { msalAuthConfiguration } from "../oauth";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public authenticated: boolean = false;
  public user: any;

  constructor(private msalService: MsalService) {
    this.initUserAccount();
  }

  /**
   * @private function, get and set the active user details to msal instance
   */
  private initUserAccount() {
    this.user = this.msalService.instance.getAllAccounts();

    if (this.user.length > 0) {
      this.msalService.instance.setActiveAccount(this.user[0]);
    }
  }

  /**
   * @public
   *
   * Function will redirected to the microsoft portal for sign in process.
   *
   * loginRedirect function is the type of `RedirectRequest` from MSAL service
   */
  public signIn() {
    let authSettingsData: RedirectRequest = {
      redirectUri: msalAuthConfiguration.msalRedirectURI,
      redirectStartPage: msalAuthConfiguration.msalRedirectURI,
      scopes: ["openid", "https://graph.microsoft.com/.default"],
    };

    this.msalService.loginRedirect(authSettingsData).subscribe((res) => {});
  }

  public signOut() {
    this.msalService.logout().subscribe((res) => {});
  }

  /**
   * @public function to get the active user details
   * @returns user details
   */
  public getUserDetails() {
    return this.msalService.instance.getActiveAccount();
  }
  public graphClient?: Client;

  async isLoggedIn() {
    let users: any = this.msalService.instance.getAllAccounts();

    if (users.length > 0) {
      this.msalService.instance.setActiveAccount(users[0]);
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    return this.authenticated;
  }

  private async getUser(): Promise<User | undefined> {
    if (!this.authenticated) return undefined;

    // Create an authentication provider for the current user
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.msalService.instance as PublicClientApplication,
      {
        account: this.msalService.instance.getActiveAccount()!,
        scopes: msalAuthConfiguration.msalB2CScopes,
        interactionType: InteractionType.Popup,
      }
    );

    // Initialize the Graph client
    this.graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });

    // Get the user from Graph (GET /me)
    const graphUser: MicrosoftGraph.User = await this.graphClient
      .api("/me")
      .select("displayName,mail")
      .get();

    this.authenticated = true;
    const user = new User();
    user.displayName = graphUser.displayName ?? "";
    // Prefer the mail property, but fall back to userPrincipalName
    user.email = graphUser.mail ?? graphUser.userPrincipalName ?? "";
    user.timeZone = graphUser.mailboxSettings?.timeZone ?? "UTC";
    // Use default avatar
    user.avatar = "/assets/shell/default-profile.svg";

    return user;
  }

  async getUsersList(teams_group_id: string, query: string) {
    // Create an authentication provider for the current user
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.msalService.instance as PublicClientApplication,
      {
        account: this.msalService.instance.getActiveAccount()!,
        scopes: msalAuthConfiguration.msalB2CScopes,
        interactionType: InteractionType.Popup,
      }
    );

    // Initialize the Graph client
    this.graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });
    console.log(query);

    const graphTeams: any = await this.graphClient
      .api(
        "/groups/" +
          teams_group_id +
          "/members" +
          (query != null && query != ""
            ? '?$search="displayName:' + query + '"'
            : "")
      )
      .headers({ ConsistencyLevel: "eventual" })
      // .select('displayName,mail,mailboxSettings,userPrincipalName')
      .get();
    return graphTeams;
  }

  async getTemGroupsList(query: string) {
    // Create an authentication provider for the current user
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.msalService.instance as PublicClientApplication,
      {
        account: this.msalService.instance.getActiveAccount()!,
        scopes: msalAuthConfiguration.msalB2CScopes,
        interactionType: InteractionType.Popup,
      }
    );

    // Initialize the Graph client
    this.graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });

    const graphTeams: any = await this.graphClient
      .api('/groups?$search="displayName:' + query + '"')
      .headers({ ConsistencyLevel: "eventual" })
      // .select('displayName,mail,mailboxSettings,userPrincipalName')
      .get();
    return graphTeams;
  }
}
