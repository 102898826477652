import { Configuration, LogLevel } from "@azure/msal-browser";

/**
 * msalClientID - MSAL client ID
 *
 * msalAuthority - MSAL identity provider
 *    Ref Links:
 *        1. https://learn.microsoft.com/en-us/dotnet/api/microsoft.authentication.webassembly.msal.msalauthenticationoptions.clientid?view=aspnetcore-7.0
 *        2. https://azuread.github.io/microsoft-authentication-library-for-objc/Classes/MSALAuthority.html
 *
 * msalKnownAuthorities
 *    Ref Links:
 *        1. https://learn.microsoft.com/en-us/dotnet/api/microsoft.authentication.webassembly.msal.msalauthenticationoptions.knownauthorities?view=aspnetcore-7.0
 *
 * msalTenantName: Tenant Name
 *
 * msalB2CScopes - Array of scopes the application is requesting access to & helps to retrieve tokens from the cache, renew an expired token with a refresh token
 *
 * msalRedirectURI - The redirect URI where authentication responses can be received by the application. It must exactly match one of the redirect URIs registered in the Azure portal.
 *
 * msalLogoutURI - The redirect URI where the window navigates after a successful logout.
 *
 * msalGraphURL & msalGraphScopes - Microsoft Graph API details need to be provided here.
 *
 */

export const msalAuthConfiguration = {
  msalClientID: "5add19fe-930b-4e03-9d51-bf20ccc91543",
  msalAuthority:
    "https://login.microsoftonline.com/773bda68-87c6-401a-b9a4-3dfb1f92e189",
  msalKnownAuthorities: [""],
  msalB2CScopes: ["User.Read"],
  msalRedirectURI: window.location.origin + "/dashboard",
  msalLogoutURI: window.location.origin,
  msalLoginFailedRoute: "/",
  msalGraphURL: "https://graph.microsoft-ppe.com/v1.0/me",
  msalGraphScopes: ["User.Read"],
};
