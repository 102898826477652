<app-header (toggleMenu)="toggleMenu()"> </app-header>
<main class="bg-white bg-logo flex items-stretch w-full minPageHeight">
  <aside
    id="sideBar"
    class="flex flex-col cursor-pointer verticalScrollContainer scrollBarStyle flexNoShrink"
  >
    <ul class="sticky top-0">
      <li
        routerLink="dashboard"
        routerLinkActive="active"
        class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
      >
        <div class="flex item-center justify-between">
          <mat-icon>dashboard</mat-icon>
          <span class="pl-3">Dashboard</span>
        </div>
      </li>
      <li
        routerLink="users"
        routerLinkActive="active"
        class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
      >
        <div class="flex item-center justify-between">
          <mat-icon>supervisor_account</mat-icon>
          <span class="pl-3 flex-shrink-0">Yard Jockeys</span>
        </div>
      </li>
      <li
        routerLink="plants"
        routerLinkActive="active"
        class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
      >
        <div class="flex item-center justify-between">
          <mat-icon>store_mall_directory</mat-icon>
          <span class="pl-3">Plants</span>
        </div>
      </li>
      <li
        routerLink="carriers"
        routerLinkActive="active"
        class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
      >
        <div class="flex item-center justify-between">
          <mat-icon>local_shipping</mat-icon>
          <span class="pl-3">Carriers</span>
        </div>
      </li>
      <li
        routerLink="suggestions"
        routerLinkActive="active"
        class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
      >
        <div class="flex item-center justify-between">
          <mat-icon>feedback</mat-icon>
          <span class="pl-3">Feedback</span>
        </div>
      </li>
      <li
        routerLink="devices"
        routerLinkActive="active"
        class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
      >
        <div class="flex item-center justify-between">
          <mat-icon>devices</mat-icon>
          <span class="pl-3">Devices</span>
        </div>
      </li>

      <ng-container *ngIf="appStore?.user?.isAdmin">
        <li
          routerLink="admin-settings"
          routerLinkActive="active"
          class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
        >
          <div class="flex item-center justify-between">
            <mat-icon>settings</mat-icon>
            <span class="pl-3">Settings</span>
          </div>
        </li>
      </ng-container>
      <ng-container *ngIf="appStore?.user?.isAdmin">
        <li
          routerLink="api-traces"
          routerLinkActive="active"
          class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
        >
          <div class="flex item-center justify-between">
            <mat-icon>build</mat-icon>
            <span class="pl-3">API Traces</span>
          </div>
        </li>
      </ng-container>
      <!-- <li
            routerLink="settings"
            routerLinkActive="active"
            class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
          >
          <div class="flex item-center justify-between">
            <mat-icon>settings</mat-icon>
            <span class="pl-3">Settings</span>
          </div>
          
          </li> -->
      <!-- <li
            routerLink="tasks"
            class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
          >
          <div class="flex item-center justify-between">
            <mat-icon>task</mat-icon>
            <span class="pl-3">Tasks</span>
          </div>
          
          </li>
          <li
            routerLink="user-logs"
            class="cursor-pointer flex items-center transition-all duration-200 ease-in-out"
          >
          <div class="flex item-center justify-between">
            <mat-icon>people</mat-icon>
            <span class="pl-3">Logs</span>
          </div>
          
          </li> -->
    </ul>
    <div
      id="logoutCTA"
      class="cursor-pointer bg-indigo-900 w-full align-items-center absolute flex bottom-0 pl-4 pr-4 py-3 transition-all duration-200 ease-in-out"
      (click)="signOut()"
    >
      <div class="flex item-center justify-between m-auto">
        <mat-icon>logout</mat-icon>
        <span class="pl-3">Logout</span>
      </div>
    </div>
  </aside>
  <section class="w-full">
    <ng-content></ng-content>
  </section>
</main>
