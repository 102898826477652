import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { environment } from "src/environments/environment";
import { MsalService } from "@azure/msal-angular";
import { AppStore } from "src/app/app.store";

@Component({
  selector: "app-shell",
  templateUrl: "./shell.component.html",
  styleUrls: ["./shell.component.scss"],
  host: {
    class: "yms-shell",
  },
})
export class ShellComponent implements OnInit {
  @ViewChild("drawer")
  drawer!: MatDrawer;

  public isProduction: boolean;

  constructor(private msalService: MsalService, public appStore: AppStore) {
    this.isProduction = environment.production;
  }

  public toggleMenu() {
    this.drawer.toggle();
  }

  // Sign out
  async signOut(): Promise<void> {
    window.location.reload();
    await this.msalService.logout().toPromise();
  }

  ngOnInit(): void {}
}
