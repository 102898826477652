import { Injectable } from "@angular/core";
import { observable, computed } from "mobx";
import { User } from "./auth/model/user";
@Injectable({
  providedIn: "root",
})
export class AppStore {
  @observable user: any = {};
  @observable settings: any = {};
}
