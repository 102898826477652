import { Injectable } from "@angular/core";
import { CanLoad, Router } from "@angular/router";
import { Observable, of, throwError, Subscription } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import { AppStore } from "src/app/app.store";
import { AppService } from "src/app/app.service";
import { AuthService } from "src/app/auth/service/auth.service";
import { User } from "src/app/auth/model/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SettingsGuard implements CanLoad {
  public subscription: Subscription = new Subscription();
  constructor(
    private msalService: MsalService,
    private authService: AuthService,
    private appService: AppService,
    private appStore: AppStore,
    private router: Router
  ) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    // let users = this.msalService.instance.getAllAccounts();
    // if (users.length > 0) {
    //   const activeAccount = users[0];
    //   console.log(activeAccount);

    //   if (!activeAccount) {
    //     // Redirect or display error if not authenticated
    //     this.router.navigate(["/dashboard"]);
    //   } else {
    //     /**
    //      * Function to get the authorization token after successful sign in.
    //      * @param activeAccount will has the signed in user details received from MSAL
    //      */
    //     this.msalService.instance.setActiveAccount(activeAccount);
    //     let reqPayloadForAcquireTokenSilent = {
    //       scopes: environment.msalB2CScopes,
    //     };
    //     this.msalService
    //       .acquireTokenSilent(reqPayloadForAcquireTokenSilent)
    //       .subscribe(
    //         (res: any) => {
    //           console.log("Setup User", res);
    //           this.appStore.user = new User();
    //           this.appStore.user.displayName = res?.account?.name || null;
    //           this.appStore.user.email = res?.account?.username || null;
    //           this.appStore.user.avatar = "/assets/shell/default-profile.svg";
    //           this.appStore.user.roles =
    //             res?.account?.idTokenClaims?.roles || [];
    //           this.appStore.user.isAuthenticated = true;
    //           this.appStore.user.isAdmin =
    //             res?.account?.idTokenClaims?.roles.includes("NBL_YMS_ADMIN")
    //               ? true
    //               : false;
    //           this.appStore.user.isSuperAdmin =
    //             res?.account?.idTokenClaims?.roles.includes(
    //               "NBL_YMS_SUPER_ADMIN"
    //             )
    //               ? true
    //               : true;

    //           /**
    //            * Once received the response, we can trigger the application APIs from here.
    //            */
    //           console.log("Triggered Settings API");
    //           this.subscription.add(
    //             this.appService.getConfigs().subscribe((response: any) => {
    //               localStorage.setItem("yms_session", response.device._id);
    //               localStorage.setItem(
    //                 "acid_session",
    //                 response.device.app_center_id
    //               );
    //               response.settings.COUNTRY_CODES = JSON.parse(
    //                 response.settings.COUNTRY_CODES
    //               );
    //               response.settings.FUNCTION_CODES = JSON.parse(
    //                 response.settings.FUNCTION_CODES
    //               );
    //               response.settings.ADMIN_USERS = JSON.parse(
    //                 response.settings.ADMIN_USERS
    //               );
    //               response.settings.SUPER_ADMIN_USERS = JSON.parse(
    //                 response.settings.SUPER_ADMIN_USERS
    //               );
    //               this.appStore.settings = response.settings;
    //               console.log("Load Settings Data");
    //             })
    //           );
    //         },
    //         (error) => {
    //           this.authService.signIn();
    //         }
    //       );
    //   }
    //   console.log("settings guard", this.appStore.settings);
    // }
    if (this.appStore.settings) {
      return true;
    } else {
      return false;
    }
  }
}
