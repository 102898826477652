import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AppStore } from "src/app/app.store";
import { User } from "src/app/auth/model/user";
import { AuthService } from "src/app/auth/service/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public showNav: boolean = false;
  @Output("toggleMenu") toggleMenu: EventEmitter<any> = new EventEmitter();

  toggleNavBar(): void {
    this.showNav = !this.showNav;
  }

  public triggerMenu() {
    this.toggleMenu.emit();
  }

  constructor(private authService: AuthService, public appStore: AppStore) {}

  ngOnInit(): void {}

  async signIn(): Promise<void> {
    await this.authService.signIn();
  }

  signOut(): void {
    this.authService.signOut();
  }
}
