import { Component } from "@angular/core";
import { AppStore } from "./app.store";
import { User } from "./auth/model/user";
import { AuthService } from "./auth/service/auth.service";
import { Subscription } from "rxjs";
import { AppService } from "./app.service";
import { MsalBroadcastService } from "@azure/msal-angular";
import { EventMessage, EventType } from "@azure/msal-browser";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public subscription: Subscription = new Subscription();
  title = "YMS";

  constructor(
    public authService: AuthService,
    public appStore: AppStore,
    public appService: AppService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit() {}

  async signIn(): Promise<void> {
    await this.authService.signIn();
  }
  async signOut(): Promise<void> {
    await this.authService.signOut();
  }
  ngOnDestroy() {
    /* Unsubscribing the Observable objects */
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
