<div
  *ngIf="!appStore.user.isAuthenticated"
  class="d-flex align-items-center h-100vh w-100 justify-content-between treeBg"
>
  <div
    class="d-flex flex-column align-items-center justify-content-between mx-auto p-2"
  >
    <img width="200px" class="yms-logo me-3" src="./assets/shell/logo.svg" />
    <!-- <img src="../assets/shell/default-profile.svg" class="d-75 mw-5" /> -->
    <br />
    <h2 class="text-white text-center w-100 fontWeight400">
      Please login with your Niagara Account to access the YMS dashboard
    </h2>
    <a (click)="signIn()">
      <button mat-raised-button color="accent">SIGN IN</button>
    </a>
    <div class="pad100"></div>
  </div>
</div>
<div *ngIf="appStore.user.isAuthenticated">
  <app-shell>
    <router-outlet></router-outlet>
  </app-shell>
</div>
