import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { MsalService } from "@azure/msal-angular";

@Injectable()
export class SuperAdminGuard {
  constructor(public msalService: MsalService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const account: any = this.msalService.instance.getActiveAccount();
    console.log(
      "super admin guard",
      route,
      account,
      account?.idTokenClaims?.roles?.includes("NBL_YMS_SUPER_ADMIN")
    );

    if (
      account !== null &&
      account?.accessToken !== null &&
      account?.idTokenClaims?.roles?.includes("NBL_YMS_SUPER_ADMIN")
    ) {
      return true;
    } else {
      return false;
    }
  }
}
